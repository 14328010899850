<template>
    <div>

        <el-main style=" padding-top: 5px;padding-bottom: initial  ">
            <el-row>
                <el-col :lg="24">
                    <div :class='[this.$store.getters.getRunTimeTest ? "warning" : "tip"]'>
                        <el-row>
                            <el-col :lg="24" style="line-height: 27px;">
                                <strong style=" font-size: 16px; ">{{this.$store.getters.getRunTimeTest ?'(测试环境,数据定期删除)':''}}当前：系统设置</strong>
                            </el-col>
                        </el-row>
                    </div>
                </el-col>
                <el-col :lg="24">
                    <el-row>
                        <el-col :sm="12" :md="24">
                            <el-button style=" margin-top: 8px;margin-bottom: 8px;margin-right: 18px; "
                                       type="danger"  size="mini" round
                                       @click="closeVisible = true">关闭系统
                            </el-button>
                            <br>
                            销售欠款单必须上传图片：<vxe-switch @change="systemSettingEvent('/admin/systemSetting/isNeedUploadImg',{isNeedUploadImg:isNeedUploadImg})" v-model="isNeedUploadImg" size="medium" open-label="是" close-label="否"></vxe-switch>
                            <br>
                            禁止超卖、禁止超库存锁定：<vxe-switch @change="systemSettingEvent('/admin/systemSetting/setBanOverSold',{banOverSold:banOverSold})" v-model="banOverSold" size="medium" open-label="是" close-label="否"></vxe-switch>
                            <br>
                            禁止批号库存超卖：<vxe-switch @change="systemSettingEvent('/admin/systemSetting/banOverSoldBatchNumber',{banOverSoldBatchNumber:banOverSoldBatchNumber})" v-model="banOverSoldBatchNumber" size="medium" open-label="是" close-label="否"></vxe-switch>
                            <br>
                            开启每天限价（销售开单订单日期“校验”每天最低限价）：<vxe-switch @change="systemSettingEvent('/admin/systemSetting/isEveryDayPrice',{isEveryDayPrice:isEveryDayPrice})" v-model="isEveryDayPrice" size="medium" open-label="是" close-label="否"></vxe-switch>
                            <br>
                            出纳单异常不能开单、不能做出纳单：<vxe-switch @change="systemSettingEvent('/admin/systemSetting/isErrorUnTransferSlip',{isErrorUnTransferSlip:isErrorUnTransferSlip})" v-model="isErrorUnTransferSlip" size="medium" open-label="是" close-label="否"></vxe-switch>
                            <br>
                            超级管理员则跳过所有校验(影响金额除外)：<vxe-switch @change="systemSettingEvent('/admin/systemSetting/adminNext',{adminNext:adminNext})" v-model="adminNext" size="medium" open-label="是" close-label="否"></vxe-switch>
                            <br>
                            <el-button style=" margin-top: 8px;margin-bottom: 8px;margin-right: 18px; "
                                       type="danger"  size="mini" round
                                       @click="systemSettingEvent('/admin/systemSetting/removeCache',null)">清理缓存
                            </el-button>
                        </el-col>
                    </el-row>
                </el-col>
            </el-row>

            <el-dialog
                    title="提示"
                    :visible.sync="closeVisible"
                    width="30%"
                    center>
                    <span>确定将会关闭系统、不能访问，需要重新开启，请联系运维工程师！</span>
                    <span slot="footer" class="dialog-footer">
                        <el-button @click="closeVisible = false">取 消</el-button>
                        <el-button type="primary" @click="systemSettingEvent('/admin/systemSetting/closeSystem'),closeVisible = false">确 定</el-button>
                    </span>
            </el-dialog>
        </el-main>
    </div>
</template>

<script>

    export default {

        data() {
            return {
                closeVisible: false,
                banOverSold: false,
                banOverSoldBatchNumber: false,
                isEveryDayPrice: false,
                isErrorUnTransferSlip: false,
                adminNext: false,
                isNeedUploadImg: false
           }
       },
        methods: {
            systemSettingEvent(url,params){
                this.$axios({
                    method: 'post',
                    url: url,
                    params:params
                }).then((response) => {          //这里使用了ES6的语法
                    //console.log(response)       //请求成功返回的数据
                    if (response.status === 200 && response.data.state == 'ok') {
                        this.$message({
                            showClose: true,
                            message: response.data.msg,
                            type: 'success'
                        });
                    }else{
                        this.$message({
                            showClose: true,
                            message: response.data.msg,
                            type: 'error'
                        });
                        return
                    }
                }).catch((error) => {
                    console.log(error)
                });
            },
            async getSettingEvent() {
                this.$axios({
                    method: 'post',
                    url: '/admin/systemSetting/getSetting',
               }).then((response) => {          //这里使用了ES6的语法
                    //console.log(response)       //请求成功返回的数据
                    if (response.status === 200 && response.data.state == 'ok') {
                        this.$message({
                            showClose: true,
                            message: response.data.msg,
                            type: 'success'
                       });
                        this.banOverSold = response.data.banOverSold
                        this.isNeedUploadImg = response.data.isNeedUploadImg
                        this.banOverSoldBatchNumber = response.data.banOverSoldBatchNumber
                        this.isEveryDayPrice = response.data.isEveryDayPrice
                        this.isErrorUnTransferSlip = response.data.isErrorUnTransferSlip
                        this.adminNext = response.data.adminNext
                   }else{
                        this.$message({
                            showClose: true,
                            message: response.data.msg,
                            type: 'error'
                       });
                        return
                   }
               }).catch((error) => {
                    console.log(error)
               });
           },
       },
        created() {
            this.getSettingEvent();
       }
   };
</script>

<style scoped>

</style>
